<template>
  <v-container fluid class="pa-8">
    <!-- <v-card flat class="mb-3"> -->
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-form
      class="mt-4 pa-4 form-height"
      v-model="valid"
      ref="form"
      v-if="identity"
    >
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('name')"
            v-model="identity.name"
            type="text"
            maxlength="256"
            :rules="formRules.required"
          />
          <v-text-field
            :label="$t('email')"
            v-model="identity.email"
            type="text"
            maxlength="256"
            :rules="formRules.required"
          />
          <v-text-field
            v-if="mode == 'create'"
            :label="$t('password')"
            v-model="identity.password"
            type="password"
            maxlength="256"
            :rules="formRules.required"
          />
          <v-text-field
            v-if="mode == 'edit'"
            :label="$t('password') + ' (' + $t('optional') + ')'"
            v-model="identity.password"
            type="password"
            maxlength="256"
            :rules="formRules.optional"
          />
          <v-text-field
            :label="$t('phone') + ' (' + $t('optional') + ')'"
            v-model="identity.phone"
            type="text"
            maxlength="256"
            :rules="formRules.optional"
          />
        </v-col>
        <v-col>
          <GsLocale
            :label="$t('locale')"
            v-model="identity.locale"
            :rules="formRules.required"
          />
          <GsTimeZone
            :label="$t('timezone')"
            v-model="identity.timezone"
            :rules="formRules.required"
          />
          <GsInfiniteDropdown
            :config="customerSelectionConfig"
            v-model="identity.ownedBy"
          />
          <MFUsersDropdown
            :label="$t('mfUser') + ' (' + $t('optional') + ')'"
            v-model="identity.mfUser"
            :rules="formRules.optional"
            :ownedBy="identity.ownedBy"
          />
          <AssetsDropdown
            :label="$t('assetId') + ' (' + $t('optional') + ')'"
            v-model="identity.assetId"
            :rules="formRules.optional"
            :ownedBy="identity.ownedBy"
          />
          <DriversDropdown
            :label="$t('driverId') + ' (' + $t('optional') + ')'"
            v-model="identity.driverId"
            :rules="formRules.optional"
            :ownedBy="identity.ownedBy"
          />
          <GroupsDropdown
            :label="$t('groups')"
            v-model="identity.group"
            :rules="formRules.optional"
            :ownedBy="identity.ownedBy"
          />
        </v-col>
      </v-row>

      <!-- errors -->
      <div class="d-flex flex-row" v-if="error">
        <span class="subtitle-1 mb-4 red--text"
          >{{ $t("form_invalid_text") }}: {{ error }}</span
        >
      </div>

      <!-- <actions> -->
      <div class="mt-6 d-flex flex-row">
        <v-spacer />
        <!-- <v-btn color="default" @click="cancel_click">
          {{ $t("cancel") }}
        </v-btn> -->
        <v-btn
          class="ml-4"
          color="primary"
          @click="save_click"
          :loading="loading"
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsLocale from "@/components/FormComponents/GsLocale";
import GsTimeZone from "@/components/FormComponents/GsTimeZone";
import MFUsersDropdown from "@/components/FormComponents/MFUsersDropdown";
import AssetsDropdown from "@/components/FormComponents/AssetsDropdown";
import DriversDropdown from "@/components/FormComponents/DriversDropdown";
import GroupsDropdown from "@/components/FormComponents/GroupsDropdown";
import GsNavButtons from "@/components/GsNavButtons";
import { GsInfiniteDropdown } from "ngt-frontend-core";

export default {
  name: "IdentityIdentity",
  components: {
    GsLocale,
    GsTimeZone,
    MFUsersDropdown,
    AssetsDropdown,
    DriversDropdown,
    GroupsDropdown,
    GsInfiniteDropdown,
    GsNavButtons
  },
  data() {
    return {
      mode: null,
      identity: null,
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true]
      },
      valid: true,
      error: null,
      loading: false
    };
  },
  computed: {
    customerSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/customers",
          sort: "name:asc",
          fields: "name,_uri",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "_uri"
          }
        },
        label: this.$t("ownedBy"),
        limit: 50,
        page: 0,
        rules: this.formRules.required,
        value: this.identity?.ownedBy
      };
    }
  },
  watch: {
    "$route.params.identityId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createIdentity();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadIdentity(v);
      }
    }
  },
  async mounted() {
    // check if customers are available?
    const checkCustomers = this.$store.getters.customers();
    if (checkCustomers || !checkCustomers?.length) {
      this.$store.dispatch("getCustomers");
    }
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createIdentity() {
      this.identity = {
        name: null,
        email: null,
        password: null,
        phone: null,
        locale: null,
        timezone: null,
        ownedBy: null,
        mfUser: null,
        group: null,
        driverId: null,
        assetId: null
      };
    },
    async loadIdentity(identityId) {
      const url = `${this.$store.state.idp_api_url}/identities/${identityId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.identity = response.data;
        // console.log(this.identity)
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/identities`;
      if (this.mode == "edit") url += `/${this.identity.identityId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";
      let data = {
        name: this.identity.name,
        email: this.identity.email,
        locale: this.identity.locale,
        timezone: this.identity.timezone,
        ownedBy: this.identity.ownedBy
      };

      if (this.identity.password) data.password = this.identity.password;
      if (this.identity.phone) data.phone = this.identity.phone;
      if (this.identity.mfUser != null) data.mfUser = this.identity.mfUser;
      if (this.identity.driverId != null)
        data.driverId = this.identity.driverId;
      if (this.identity.assetId != null) data.assetId = this.identity.assetId;

      data.group = this.identity.group || [];

      // console.log("to be saved ", data)

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        // console.log(response.status)
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }

        // this.identity = response.data
        // console.log(this.identity)
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.form-height {
  height: calc(100vh - 200px);
  /* background-color: rgb(0, 255, 174); */
}
</style>
